.jsoneditor-menu{
    background-color: black!important;
    border-bottom: black!important;
}
.jsoneditor{
border:solid black!important;
}
.eds-snack{
    background-color: #f0fff0!important;
}

.eds-snack-error {
    background-color: #FF4E4A!important;
}
.jsoneditor-sort{
    display:none!important;
}
.jsoneditor-transform{
    display:none!important;
}

.jsoneditor-contextmenu-button{
    display:none!important;
}

.eds-button-group {
    flex-direction: column;
}
